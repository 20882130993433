<template>
  <div class="navigation-container d-flex bg-white px-2 px-md-4">
    <a
      v-for="content in contentOptions"
      :key="content.name"
      class="ml-4 text-decoration-none text-dark"
      :class="{ active: value === content.name }"
      href="javascript:;"
      :value="value"
      @click.prevent="$emit('input', content.name)"
    >
      <p class="title-width font-size-sm my-3 text-center text-md-left">{{ content.title }}</p>
    </a>
  </div>
</template>

<script>
export default {
  name: 'NavigationOptions',
  props: {
    value: {
      type: String,
    },
    contentOptions: {
      required: true,
      type: Array,
    },
  },
};
</script>

<style lang="scss" scoped>
.navigation-container {
  border-top: 1px solid #dee2e6 !important;
}
.active {
  border-bottom: 3px solid $purple-dark;
  font-weight: bold;
}

.title-width {
  width: max-content;
}

@media screen and (max-width: $screen-sm) {
  .navigation-container {
    overflow-x: scroll;
    border-bottom: 1px solid #dee2e6 !important;
  }
}
</style>
